import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10
  },
  image: {
    maxWidth: 220,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
    },
  },
  media: {
    height: 200,
  },
  card: {
    width: '100%',
  },
  paper: {
    width: '100%',
    "& img": {
      width: '100%',
    },
    "& .item": {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    "& .content": {
      width: '100%',
      padding: theme.spacing(2),
      paddingRight: theme.spacing(4),
    }
  }
}));

const Sponsor = ({ item, large }) => {
  const classes = useStyles();
  if (!item.url) {
    return <img src={item.imageUrl} className={classes.image} />
  }
  return (
    <a href={item.url} target="_blank" rel="noreferrer noopener">
      <img src={item.imageUrl} className={classes.image} style={large ? { maxWidth: 600 } : {}} />
    </a>
  );
}

const SponsorCardHorizontal = ({ item }) => {
  const classes = useStyles();
  return (
    <Card className={classes.paper}>
      <CardActionArea component={item.url ? "a" : undefined} href={item.url ? "//" + item.url : undefined} rel={item.url ? "noopener noreferrer" : undefined} target={item.url ? "_blank" : undefined}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <img src={item.imageUrl} />
          </Grid>
          <Grid item xs={12} sm={6} className="item">
            <div className="content">
              <Typography variant="body2" align="left" color="textSecondary">{item.description}</Typography>
            </div>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}

const SponsorCard = ({ item }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardActionArea component={item.url ? "a" : undefined} href={item.url ? item.url : undefined} rel={item.url ? "noopener noreferrer" : undefined} target={item.url ? "_blank" : undefined}>
        {/* <CardMedia
          className={classes.media}
          image={item.imageUrl}
          title={item.name}
        /> */}
        <CardContent style={{ minHeight: item.description ? 230 : 'auto' }}>
          <Typography gutterBottom variant="h5" component="h2">
            {item.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" align="left">
            {item.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      {item.email &&
        <CardActions>
          <Button size="small" color="primary" component="a" href={`mailto:"${item.name}"<${item.email}>`}>
            Email {item.name}
          </Button>
        </CardActions>
      }
    </Card>
  );
}

const Sponsors = ({ data, showCards = false, horizontalCards = false, xs = 6, sm = 4, md = 3, lg = 3, large = false }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify="space-evenly" alignItems="center">
        {data.map((item, index) => (
          <Grid key={index} item xs={xs} sm={sm} md={md} lg={lg}>
            {showCards ? (horizontalCards ? <SponsorCardHorizontal item={item} /> : <SponsorCard item={item} />) : <Sponsor item={item} large={large} />}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Sponsors;
