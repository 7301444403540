import React from 'react';
import draftToHtml from 'draftjs-to-html'
import Typography from '@material-ui/core/Typography';

const EventCustomPage = ({ data }) => {

  const html = draftToHtml(JSON.parse(data.text), undefined, undefined, item => { 
    if (item.type !== "EMBEDDED_LINK")
      return;
    const { data } = item;
    return `<iframe frameborder="0" width="${data.width}" height="${data.height}" src="${data.src}" title="Preview recording" allowfullscreen=""></iframe>`;
  });

  return (
    <>
      <br />
      <br />
      {/* <Typography variant="h4" component="h1" gutterBottom>{data.name}</Typography> */}
      {data.text && <Typography component="div" dangerouslySetInnerHTML={{ __html: html }} />}
      <br />
      <br />
    </>
  );
}

export default EventCustomPage;